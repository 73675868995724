<template>
  <div class="overview">
    <NavBer></NavBer>
    <div class="bg1">
        <img src="../assets/images/dynamic/24.jpg" alt="">
    </div>
    <div class="w content">
      <el-row>
        <el-col :span="24"
          ><div class="grid-content ">
               <div class="profile">
                 <h2>公司</h2>
                 <h3>company</h3>
                 <p class="contenttext"> <i class="iconbox el-icon-office-building"></i>白塔寺药店</p>
                 <span>白塔寺药店因紧邻妙应寺白塔而得其名，是一家拥有153年历史、以经营地道中药饮片、高档参茸补品为特色的“中华老字号”药店。</span>
               </div>
            </div
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="24"
          ><div class="grid-content ">
               <div class="culture1">
                 <h2>简介</h2>
                 <div style="text-align: left; width: 950px; margin: 0 auto; color: #ffffff;">白塔寺药店始建于清同治十一年（公元1872年），是一家以经营道地中药饮片、高档参茸滋补品特色的药店。秉承“以德经商，以德待客”的经营理念，在发挥特色的同时扩大经营品种和经营范围，现以成为经营地道中药饮片和高档参茸补品，以及全国名、特、优、新中西药品，进口药品和家庭医疗器械的大型综合药店。白塔寺药店因位临阜成门内的妙应寺白塔而得其名，今日殿堂牌匾“白塔寺药店”五个大字，系老舍先生的夫人，著名书画家胡洁清女士题于1980年3月。</div>
                 
               </div>
            </div
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="24"
          ><div class="grid-content ">
               <div class="culture">
                 <h2>地址</h2>
                 <h3>address</h3>
                 <div class="contenttext"><i class="iconbox el-icon-location-information"></i>北京市西城区阜成门内大街165号</div>
                 
               </div>
            </div
        ></el-col>
      </el-row>
     
      
    </div>
    <Footbar></Footbar>
  </div>
</template>

<script>
import NavBer from "../components/nav";
import Footbar from "../components/footbar";
export default {
  components: {
    NavBer,
    Footbar,
  },
};
</script>

<style scoped>
.overview {
  padding-top: 90px;
}
.bg1 {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}
.bg1 img {
  width: 100%;
  height: 100%;
}
.content {
  margin-top: 60px;
}
.profile,
.culture,
.culture1{
  width: 100%;
  /* height: 300px; */
 text-align: center;
 background: -webkit-linear-gradient(top left,#F0FF00,#58CFFB);
 padding-top: 50px;
 padding-bottom: 100px;
 
}
.profile h2 {
  color: #ffffff;
}
.profile h3 {
  color: #130CB7;
}
.profile p{
  
  width: 400px;
  margin: 30px auto 10px;
}
.culture {
 background: -webkit-linear-gradient(top left,#43CBFF,#9708CC);
}
.culture1 {
 background: -webkit-linear-gradient(top left,#ff4382,#08cc71);
}
.culture1 h2 {
  color: #ffffff;
}
.culture1 h3 {
  color: #130CB7;
}
.culture1 p{
  
  width: 400px;
  margin: 30px auto 10px;
}
.culture h2 {
  color: #ffffff;
}
.culture h3 {
  color: #130CB7;
}
.culture p{
  
  width: 400px;
  margin: 30px auto 10px;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 300px;
}
.contenttext{
  width: 600px;
  margin: 30px auto 10px;
  color: #fff;
  /* text-align: left; */
  
  }
  .iconbox {
    /* background-color: #657ae9; */
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
    margin-right: 10px;
    }
</style>
