<template>
    <div class="Footbar bg contact">
        <div class="w">
            <div class="content">
                <p>白塔寺药店</p>
                <p>电话： 010-66160023</p>
                <p>地址：北京市西城区阜成门内大街165号</p>
                <p>互联网药品信息服务资格证书编号：（京）-非经营性-2020-0034</p>
                <div class="content">
            <a href="https://beian.miit.gov.cn/" target="_blank">备案号：京ICP备2022019410号-3</a>
        </div>
            </div>

         
        </div>
    </div>
</template>

<script>
export default {
    name: "Footbar"
}
</script>

<style scoped>
.bg {
    color: #fff;
    background-image: linear-gradient(to right, #1eb8a0, #4998c1);
}

.contact {
    padding: 50px 0 30px 0;
    margin-top: 60px;
}

.company {
    margin-right: 50px;
}

.content p {
    margin-bottom: 10px;
   
}
.content a {
    color: #ffffff;
}
</style>