<template>
  <div class="NavBer">
     <div class="">
         <div>
             <ul class="flex nav arouter-linkgn-center justify-between texts"
             >
             <router-link to="/" class="logo">
                         <img src="../../assets/images/logo.jpg">
                 </router-link>
                 
                 <router-link to="/">首页</router-link>
                 <router-link  to="/product">产品中心</router-link>
                 <router-link  to="/overview">关于我们</router-link>
                 <router-link  to="/about">联系我们</router-link>
                
             </ul>
         </div>
     </div>
  </div>
</template>

<script>
export default {
  name:"NavBar"
}
</script>

<style scoped>
.NavBer {
    position: fixed;
    background-color:#d4efea;
    width: 100%;
    top:0;
    z-index: 1000;
    border-bottom: 0.5px solid #ccc;
    z-index: 99999;

}
.logo {
    width: 500px;
    height: 90px;
    background-color: #ffffff;
    text-align: center;
    
}
.logo img {
    width: 80px;
    height: 80px;
    /* text-align: center; */
    vertical-align: middle;
}
.texts {
    font-size: 18px;
    font-weight: 400;
    color: #000;
    /* vertical-align:middle; */
   
}
 .texts a:hover {
  color: #02b2b5;
}
.router-link-exact-active {
  color: #02b2b5;
}

.nav {
  
    width: 1000px;
    height: 90px;
    line-height: 90px;
    
}
</style>